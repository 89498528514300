import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import DetailView from "../components/Templates/detailView";
import PortfolioContent from "../components/Portfolio/portfolioContent";
import IS_CORPORATE from '../config/siteConfig';



const Portfolio = ({ data: { content:contentCorporate,contentVentures, portfolios }, pageContext: { locale }, location }) => {
    const lang = React.useContext(LocaleContext)
    //const i18n = lang.i18n[lang.locale]
    const content = IS_CORPORATE ? contentCorporate : contentVentures;

    return (
        <>
            <SEO
            title={'Portfolio'}
            description={content.data.seo_portfolio_beschreibung.text}
            keywords={content.data.seo_portfolio_keywords.text ?content.data.seo_portfolio_keywords.text.split(', ') :
            [`Gesundheit`, `Portfolio`, `Referenzen`]
          }
            />
            <DetailView>
                <PortfolioContent 
                    onlyGrid={true}
                    title={'Portfolio'}
                    text={content.data.portfolio_content.html}
                    subtitle={content.data.portfolio_subheadline.text} 
                    data={portfolios}/>
            </DetailView>
        </>
    )
}


export default Portfolio


Portfolio.propTypes = {
    data: PropTypes.shape({
        portfolios: PropTypes.object,
         content: PropTypes.object.isRequired,
         }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
        query PortfolioPageQuery($locale: String!) {
           content: prismicHomepage(lang: { eq: $locale }) {
             data {
               portfolio_content {
                 html
               }
               portfolio_subheadline {
                 text
               }
               seo_portfolio_beschreibung {
                text
              }
              seo_portfolio_keywords {
                text
              }
             }
           }
           contentVentures: prismicHomepageVentures(lang: { eq: $locale }) {
            data {
              portfolio_content {
                html
              }
              portfolio_subheadline {
                text
              }
              seo_portfolio_beschreibung {
               text
             }
             seo_portfolio_keywords {
               text
             }
            }
          }
           portfolios: allPrismicPortfolio(
             filter: { lang: { eq: $locale } }
             sort: { fields: [uid], order: ASC }
           ) {
             edges {
               node {
                 uid
                 data {
                   title {
                     text
                   }
                   content {
                     html
                   }
                   show_on_ventures_page
                   color
                   logo {
                     alt
                     url
                     localFile {
                       childImageSharp {
                         fluid(maxWidth: 1200, quality: 90) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                   url {
                     link_type
                     url
                     target
                   }
                 }
               }
             }
           }
         }
       `
